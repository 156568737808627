// @ts-nocheck
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import axios from "axios";
import { URL_BASE } from "../../config/config";

export interface IFormInput {
    factura_id: number;
    fecha_pago: string;
    monto_pagado: number;
    voucher_transferencia: string;
    estado_pago: string;
    cliente_id: number;
    monto_total: number;
    monto_abono?: number;
}

interface FormularioProps {
    onSubmit: (data: IFormInput) => void;
    errors: any;
}

const Formulario: React.FC<FormularioProps> = ({ onSubmit, errors }) => {
    const { register, setValue, watch } = useFormContext();
    const [facturas, setFacturas] = useState([]);
    const [clientes, setClientes] = useState([]);
    const [montoTotal, setMontoTotal] = useState(0);
    const [isAbono, setIsAbono] = useState(false);
    const [isPaid, setIsPaid] = useState(false); // Estado para verificar si la factura está pagada

    const clienteId = watch("cliente_id");
    const selectedMonth = watch("month") || new Date().getMonth() + 1;
    const selectedYear = watch("year") || new Date().getFullYear();

    useEffect(() => {
        const fetchClientes = async () => {
            try {
                const { data } = await axios.get(`${URL_BASE}/clientes?opcion=all`);
                setClientes(data);
            } catch (error) {
                console.error("Error fetching clientes:", error);
            }
        };

        fetchClientes();
        setValue("fecha_pago", new Date().toISOString().split("T")[0]);
        setValue("month", selectedMonth);
        setValue("year", selectedYear);
        setValue("monto_abano", 0);
        setValue("estado_pago", 'pago total');
    }, [setValue]);

    const fetchFacturas = async (clientId: number, month: number, year: number) => {
        try {
            const { data } = await axios.get(`${URL_BASE}/facturas?opcion=get&filtro[cliente_id]=${clientId}&month=${month}&year=${year}`);
            setFacturas(data);
        } catch (error) {
            console.error("Error fetching invoices data:", error);
        }
    };

    useEffect(() => {
        if (clienteId) {
            fetchFacturas(clienteId, selectedMonth, selectedYear);
        }
    }, [clienteId, selectedMonth, selectedYear]);

    const handleFacturaChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const facturaId = Number(event.target.value);
        const facturaSeleccionada = facturas.find(factura => factura.factura_id === facturaId);
        if (facturaSeleccionada) {
            setMontoTotal(facturaSeleccionada.monto_total);
            setValue("monto_pagado", facturaSeleccionada.monto_total);
            setIsPaid(facturaSeleccionada.estado === "pagado"); // Verificar si la factura está pagada
        }
    };

    const handleToggleAbono = () => {
        const nuevoEstadoAbono = !isAbono;
        setIsAbono(nuevoEstadoAbono);

        if (nuevoEstadoAbono) {
            // Si es abono, establece el estado de pago en "abono"
            setValue("estado_pago", "abono");
            setValue("monto_abono", 0); // Limpia el campo para llenarlo como abono
        } else {

            // Si no es abono, establece el estado de pago en "pago total"
            setValue("estado_pago", "pago total");
            // Asegura que "monto_abono" siempre esté presente en la solicitud
            setValue("monto_abono", 0); // Envía 0 si no es abono
        }
    };

    return (
        <form onSubmit={onSubmit}>
            <div className="row">
                <div className="col-md-4 mb-10">
                    <label className="form-label">Cliente</label>
                    <select
                        className="form-select form-select-solid"
                        {...register("cliente_id", { required: true })}
                    >
                        <option value="">Seleccione un cliente</option>
                        {clientes.map((cliente) => (
                            <option key={cliente.cliente_id} value={cliente.cliente_id}>
                                {cliente.nombre + " " + cliente.apellido}
                            </option>
                        ))}
                    </select>
                    {errors.cliente_id && <span className="text-danger">Este campo es obligatorio</span>}
                </div>

                <div className="col-md-4 mb-10">
                    <label className="form-label">Mes</label>
                    <select
                        className="form-select form-select-solid"
                        {...register("month")}
                    >
                        {[...Array(12)].map((_, index) => (
                            <option key={index + 1} value={index + 1}>
                                {new Date(0, index).toLocaleString("default", { month: "long" })}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="col-md-4 mb-10">
                    <label className="form-label">Año</label>
                    <select
                        className="form-select form-select-solid"
                        {...register("year")}
                    >
                        {[...Array(5)].map((_, index) => (
                            <option key={index} value={selectedYear - index}>
                                {selectedYear - index}
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            <div className="row">
                <div className="col-md-6 mb-10">
                    <label className="form-label">Nº de Factura</label>
                    <select
                        className="form-select form-select-solid"
                        {...register("factura_id", { required: true })}
                        onChange={handleFacturaChange}
                    >
                        <option value="">Seleccione una factura</option>
                        {facturas.map((factura) => (
                            <option key={factura.factura_id} value={factura.factura_id}>
                                {factura.factura_id} - {factura.monto_total}
                            </option>
                        ))}
                    </select>
                    {errors.factura_id && <span className="text-danger">Este campo es obligatorio</span>}
                    {isPaid && (
                        <span className="text-danger mt-2">Esta factura ya ha sido pagada y no se puede procesar otro pago.</span>
                    )}
                </div>

                <div className="col-md-6 mb-10">
                    <label className="form-label">Voucher de Transferencia</label>
                    <input
                        type="text"
                        className="form-control form-control-solid"
                        {...register("voucher_transferencia", { required: true })}
                    />
                    {errors.voucher_transferencia && <span className="text-danger">Este campo es obligatorio</span>}
                </div>
            </div>

            <div className="row">
                <div className="col-md-6 mb-10">
                    <label className="form-label">Fecha de Pago</label>
                    <input
                        type="date"
                        className="form-control form-control-solid"
                        {...register("fecha_pago", { required: true })}
                        max={new Date().toISOString().split("T")[0]}
                    />
                    {errors.fecha_pago && <span className="text-danger">Este campo es obligatorio</span>}
                </div>

                <div className="col-md-6 mb-10">
                    <label className="form-label">Monto Total</label>
                    <input
                        type="number"
                        className="form-control form-control-solid"
                        value={montoTotal}
                        disabled={!isAbono}
                        {...register("monto_total")}
                    />
                </div>
            </div>

            {isAbono && (
                <div className="row">
                    <div className="col-md-6 mb-10">
                        <label className="form-label">Monto del Abono</label>
                        <input
                            type="number"
                            className="form-control form-control-solid"
                            {...register("monto_abono", { required: isAbono })}
                        />
                        {errors.monto_abono && <span className="text-danger">Este campo es obligatorio</span>}
                    </div>
                </div>
            )}

            <div className="row">
                <div className="col-md-6 mb-10">
                    <label className="form-label">Pago Total o Abono</label>
                    <div className="form-check form-switch">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            checked={isAbono}
                            onChange={handleToggleAbono}
                        />
                        <label className="form-check-label">Abono</label>
                    </div>
                </div>


            </div>

            <div className="d-flex justify-content-end">
                <button type="submit" className="btn btn-primary" disabled={isPaid}>Generar</button>
            </div>
        </form>
    );
};

export default Formulario;



