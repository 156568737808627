// @ts-nocheck
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import Select from 'react-select';

export interface IFormInput {
    lectura_id?: number;
    client_id: number;
    direccion_id: number;
    fecha_lectura: string;
    lectura_anterior: number;
    lectura_actual: number;
    consumo_kwh: number;
    estado_lectura: string; // Nuevo campo agregado
    fecha_vencimiento?: string;
    fecha_proxima_lectura?: string;
}

interface FormularioProps {
    isEdit: boolean;
    onSubmit: (data: IFormInput) => void;
    errors: any;
    clientes: Array<any>;
    direcciones: Array<any>;
    handleClientChange: (clientId: number) => void;
}

const Formulario: React.FC<FormularioProps> = ({
                                                   isEdit,
                                                   onSubmit,
                                                   errors,
                                                   clientes,
                                                   direcciones,
                                                   handleClientChange
                                               }) => {
    const { register, setValue, watch, trigger } = useFormContext();
    const selectedClientId = watch("client_id");
    const selectedDireccionId = watch("direccion_id");
    const lecturaAnterior = watch("lectura_anterior");
    const lecturaActual = watch("lectura_actual");
    const estadoLectura = watch("estado_lectura");

    useEffect(() => {
        if (!isEdit) {
            setValue("client_id", null);
            setValue("direccion_id", null);
            setValue("lectura_anterior", '');
            setValue("lectura_actual", '');
            setValue("consumo_kwh", 0);
            setValue("estado_lectura", "Pendiente"); // Valor predeterminado
        }
        // Establecer la fecha de lectura a la fecha actual
        setValue("fecha_lectura", new Date().toISOString().split('T')[0]);
    }, [isEdit, setValue]);

    useEffect(() => {
        if (estadoLectura === "Pendiente") {
            setValue('lectura_actual', 0);
            setValue('consumo_kwh', 0);
            trigger(['lectura_actual', 'consumo_kwh']);
        }
    }, [estadoLectura, setValue, trigger]);

    useEffect(() => {
        // Calcular consumo_kwh cuando cambian lectura_anterior o lectura_actual
        const anterior = parseFloat(lecturaAnterior);
        const actual = parseFloat(lecturaActual);
        if (!isNaN(anterior) && !isNaN(actual)) {
            const consumo = actual - anterior;
            setValue('consumo_kwh', consumo >= 0 ? consumo : 0);
            // Opcional: Validar consumo_kwh después de establecer el valor
            trigger('consumo_kwh');
        } else {
            setValue('consumo_kwh', 0);
        }
    }, [lecturaAnterior, lecturaActual, setValue, trigger]);

    return (
        <form onSubmit={onSubmit}>
            {/* Selección de Cliente */}
            <div className="mb-10 fv-row fv-plugins-icon-container">
                <label className="form-label">Cliente</label>
                <Select
                    options={clientes.map(cliente => ({
                        value: cliente.cliente_id,
                        label: `${cliente.nombre} ${cliente.apellido}`
                    }))}
                    value={clientes.find(cliente => cliente.cliente_id === selectedClientId) || null}
                    onChange={(selectedOption) => {
                        const selectedValue = selectedOption ? selectedOption.value : null;
                        setValue('client_id', selectedValue);
                        handleClientChange(selectedValue!);
                    }}
                    isDisabled={isEdit}
                    isSearchable={true}  // Habilita el buscador interno
                    placeholder="Selecciona un cliente..."
                    noOptionsMessage={() => "No se encontraron opciones"}
                />
                {errors.client_id && <span className="text-danger">Este campo es obligatorio</span>}
            </div>

            {/* Selección de Dirección */}
            <div className="mb-10 fv-row fv-plugins-icon-container">
                <label className="form-label">Dirección</label>
                <select
                    className="form-control"
                    value={selectedDireccionId || ""}
                    onChange={(e) => {
                        const direccionId = e.target.value ? parseInt(e.target.value) : null;
                        setValue('direccion_id', direccionId);
                    }}
                >
                    <option value="">Selecciona una dirección...</option>
                    {direcciones.map((direccion) => (
                        <option key={direccion.direccion_id} value={direccion.direccion_id}>
                            {direccion.calle}
                        </option>
                    ))}
                </select>
                {errors.direccion_id && <span className="text-danger">Este campo es obligatorio</span>}
            </div>

            {/* Fecha de Lectura */}
            <div className="mb-10 fv-row fv-plugins-icon-container">
                <label className="form-label">Fecha de Lectura</label>
                <input
                    type="date"
                    className="form-control form-control-solid"
                    {...register("fecha_lectura", { required: true })}
                    disabled={isEdit}
                />
                {errors.fecha_lectura && <span className="text-danger">Este campo es obligatorio</span>}
            </div>

            {/* Selección de Estado de Lectura */}
            <div className="mb-10 fv-row fv-plugins-icon-container">
                <label className="form-label">Estado de Lectura</label>
                <select
                    className="form-control"
                    {...register("estado_lectura", { required: "Este campo es obligatorio" })}
                >
                    <option value="">Selecciona un estado...</option>
                    <option value="Pendiente">Pendiente</option>
                    <option value="Realizada">Realizado</option>
                </select>
                {errors.estado_lectura && <span className="text-danger">{errors.estado_lectura.message}</span>}
            </div>

            {/* Lectura Anterior (kWh) */}
            <div className="mb-10 fv-row fv-plugins-icon-container">
                <label className="form-label">Lectura Anterior (kWh)</label>
                <input
                    type="number"
                    className="form-control form-control-solid"
                    {...register("lectura_anterior", {
                        required: "Este campo es obligatorio",
                        min: { value: 0, message: "Debe ser un valor positivo" },
                        validate: (value) => {
                            const actual = parseFloat(watch("lectura_actual"));
                            if (estadoLectura !== "Pendiente" && !isNaN(actual)) {
                                return parseFloat(value) <= actual || "La lectura anterior no puede ser mayor a la lectura actual";
                            }
                            return true;
                        }
                    })}
                />
                {errors.lectura_anterior && <span className="text-danger">{errors.lectura_anterior.message}</span>}
            </div>

            {/* Lectura Actual (kWh) */}
            <div className="mb-10 fv-row fv-plugins-icon-container">
                <label className="form-label">Lectura Actual (kWh)</label>
                <input
                    type="number"
                    className="form-control form-control-solid"
                    {...register("lectura_actual", {
                        required: "Este campo es obligatorio",
                        min: { value: 0, message: "Debe ser un valor positivo" },
                        validate: (value) => {
                            const anterior = parseFloat(watch("lectura_anterior"));
                            if (estadoLectura !== "Pendiente" && !isNaN(anterior)) {
                                return parseFloat(value) >= anterior || "La lectura actual no puede ser menor a la lectura anterior";
                            }
                            return true;
                        }
                    })}
                    disabled={estadoLectura === "Pendiente"} // Deshabilitar si estado es Pendiente
                />
                {errors.lectura_actual && <span className="text-danger">{errors.lectura_actual.message}</span>}
            </div>

            {/* Consumo Total (kWh) */}
            <div className="mb-10 fv-row fv-plugins-icon-container">
                <label className="form-label">Consumo Total del Mes (kWh)</label>
                <input
                    type="number"
                    className="form-control form-control-solid"
                    {...register("consumo_kwh")}
                    value={watch("consumo_kwh") || 0}
                    disabled
                />
            </div>



            {/* Botón de Envío */}
            <div className="d-flex justify-content-end">
                <button type="submit" className="btn btn-primary">Guardar</button>
            </div>
        </form>
    );

};

export default Formulario;











