// @ts-nocheck
import React, { useEffect, useState } from "react";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import axios from "axios";
import { URL_BASE } from "../../config/config";
import { Card } from "../../components/ui/Card";
import { Modal } from "../../components/ui/Modal";
import Swal from "sweetalert2";
import { PrivateLayout } from "../../layouts/PrivateLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faFilter, faDownload } from "@fortawesome/free-solid-svg-icons";
import { IFormInput } from "./Formulario";
import Formulario from "./Formulario";
import Table from "./Table";
import Filtros from "./Filtros";
import ResumenMes from "./ResumenMes";

export const Facturas = () => {
    const methods = useForm<IFormInput>();
    const { reset, setValue, handleSubmit, formState: { errors } } = methods;
    const [facturas, setFacturas] = useState([]);
    const [clientes, setClientes] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);
    const [isModalShow, setIsModalShow] = useState(false);
    const [titulo, setTitulo] = useState('');
    const [mesActual, setMesActual] = useState(new Date().getMonth() + 1);
    const [yearActual, setYearActual] = useState(new Date().getFullYear());
    const [showFilters, setShowFilters] = useState(false);
    const [resumen, setResumen] = useState({ recaudado: 0, pagadas: 0, vencidas: 0 });
    const [params, setParams] = useState({
        opciones: 'listPage',
        orderPor: 'cliente_id',
        order: 'asc',
        perpage: 10,
        filters: {},
        page: 1
    });

    const [paginate, setPaginate] = useState([]);
    useEffect(() => {
        getClientes();
        fetchData(mesActual, yearActual, null);
    }, []);

    useEffect(() => {
        fetchResumen(mesActual, yearActual, selectedClient?.value);
    }, [mesActual, yearActual, selectedClient]);

    const fetchData = async (month: number, year: number, clientId: number | null) => {
        try {
            const { data } = await axios.get(`${URL_BASE}/facturas?opcion=paginate&month=${month}&year=${year}${clientId ? `&filtro[cliente_id]=${clientId}` : ''}`);
            setFacturas(data.data);
            setPaginate(data.links);
        } catch (error) {
            console.error("Error fetching invoices data:", error);
        }
    }

    const fetchResumen = async (month: number, year: number, clientId: number | null) => {
        try {
            const { data } = await axios.get(`${URL_BASE}/facturas?opcion=get&month=${month}&year=${year}${clientId ? `&filtro[cliente_id]=${clientId}` : ''}`);

            console.log(data)
            const totalRecaudado = data.reduce((sum, factura) => sum + (factura.estado === 'pagado' ? factura.monto_total : 0), 0);
            const totalPendiente = data.reduce((sum, factura) => sum + (factura.estado === 'pendiente' ? factura.monto_total : 0), 0);
            const totalPagadas = data.filter(factura => factura.estado === 'pagado').length;
            const totalVencidas = data.filter(factura => factura.estado === 'vencido').length;
            setResumen({ recaudado: totalRecaudado, pagadas: totalPagadas, vencidas: totalVencidas , pendiente: totalPendiente});
        } catch (error) {
            console.error("Error fetching resumen data:", error);
        }
    }

    const getClientes = async () => {
        try {
            const { data } = await axios.get(`${URL_BASE}/clientes?opcion=get`);
            setClientes(data);
        } catch (error) {
            console.error("Error fetching clients:", error);
        }
    }

    const handleMonthChange = (event: { target: { value: string; }; }) => {
        const month = parseInt(event.target.value);
        setMesActual(month);
        fetchData(month, yearActual, selectedClient?.value);
        fetchResumen(month, yearActual, selectedClient?.value);
    }

    const handleYearChange = (event: { target: { value: string; }; }) => {
        const year = parseInt(event.target.value);
        setYearActual(year);
        fetchData(mesActual, year, selectedClient?.value);
        fetchResumen(mesActual, year, selectedClient?.value);
    }

    const handleFilterChange = (client: any) => {
        setSelectedClient(client);
        fetchData(mesActual, yearActual, client?.value);
        fetchResumen(mesActual, yearActual, client?.value);
    }

    const handleReset = () => {
        setSelectedClient(null);
        fetchData(mesActual, yearActual, null);
        fetchResumen(mesActual, yearActual, null);
    }

    const newFactura = () => {
        reset();
        setTitulo('Generar Facturas');
        setIsModalShow(true);
    }

    const closeModal = () => {
        reset();
        setIsModalShow(false);
    }

    const onSubmit: SubmitHandler<IFormInput> = async (data) => {
        try {
            Swal.fire({
                title: 'Esperando...',
                text: 'Generando facturas!',
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading();
                },
            });

            const response = await axios.post(`${URL_BASE}/facturas`, data);

            Swal.close();

            if (response.status === 201 || response.status === 200) {
                Swal.fire(
                    'Generadas!',
                    `Facturas del mes ${data.mes} y año ${data.year} generadas.`,
                    'success'
                );
                reset();
                closeModal();
                fetchData(data.mes, data.year, selectedClient?.value);
                fetchResumen(data.mes, data.year, selectedClient?.value);
            }
        } catch (e) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Ocurrió un error al generar las facturas!',
            });
        }
    }

    const handleChangePage = async (page: number) => {
        const filters = {
            ...params,
            page: page,
        };
        setParams(filters);

        const {data , status } = await axios.get(`${URL_BASE}/facturas?opcion=paginate&page=${page}&month=${mesActual}&year=${yearActual}`)

        if (status === 200) {
            setFacturas(data.data);
            setPaginate(data.links);
        }
    };

    return (
        <PrivateLayout>
            <div className="container mt-5">
                <Card
                    title="Facturas"
                    toolActions={() => (
                        <div className="btn-group">
                            <button onClick={newFactura} className="btn btn-warning btn-sm">
                                <FontAwesomeIcon icon={faPlus} /> Generar Facturas
                            </button>
                            <button onClick={() => setShowFilters(!showFilters)} className="btn btn-info btn-sm">
                                <FontAwesomeIcon icon={faFilter} /> Filtrar
                            </button>
                        </div>
                    )}
                >
                    <ResumenMes
                    resumen={resumen}
                />
                    {showFilters && (
                        <Filtros
                            handleMonthChange={handleMonthChange}
                            handleYearChange={handleYearChange}
                            handleFilterChange={handleFilterChange}
                            handleReset={handleReset}
                            mesActual={mesActual}
                            yearActual={yearActual}
                            clientes={clientes}
                            selectedClient={selectedClient}
                        />
                    )}

                    <Table
                        facturas={facturas}
                        paginate={paginate}
                        handleChangePage={handleChangePage}
                    />


                </Card>
            </div>

            <Modal show={isModalShow} handleClose={closeModal} title={titulo}>
                <FormProvider {...methods}>
                    <Formulario
                        onSubmit={handleSubmit(onSubmit)}
                        errors={errors}
                    />
                </FormProvider>
            </Modal>
        </PrivateLayout>
    );
}

